<template>
  <div>
    <work-view-detail :result="result" :is-show-loading="isShowLoading"/>
    <work-view-date-of-work :result="result" :is-show-loading="isShowLoading"/>
    <work-view-contractor :result="result" :is-show-loading="isShowLoading"/>
    <work-view-jsa :work-id="id" :card-collapsed="true"/>
    <work-view-activity :work-id="id" :card-collapsed="true"/>

    <b-row class="my-3">
      <b-col md="6" order="2" order-md="1">
        <b-button variant="danger" class="mb-1" :block="setBtnBlock" :size="setBtnSize" :disabled="isBtnDisabled" @click="disapproveData">
          <b-spinner small v-if="isBtnDisabled"/>
          <feather-icon icon="XIcon" :size="setIconSize" v-else/>
          {{ $t('general.btnDisapprove') }}
        </b-button>
      </b-col>
      <b-col md="6" class="text-right" order="1" order-md="1">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-end">
          <div class="align-self-stretch align-self-md-center order-2 order-md-1" v-if="result.workStep >= 2 && result.workStep <= 5">
            <b-button variant="warning" class="mb-1 mr-1" :block="setBtnBlock" :size="setBtnSize" @click="sendBackData" :disabled="isBtnDisabled">
              <feather-icon icon="ChevronLeftIcon" :size="setIconSize"/>
              {{ $t('general.btnSendBack') }}
            </b-button>
          </div>

          <div class="align-self-stretch align-self-md-center order=1 order-md-2">
            <b-button variant="success" class="mb-1" :block="setBtnBlock" :size="setBtnSize" :disabled="isBtnDisabled" @click="approveData">
              <b-spinner small v-if="isBtnDisabled"/>
              <feather-icon icon="CheckIcon" :size="setIconSize" v-else/>
              {{ $t('general.btnApprove') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal
        ref="modal-approve"
        cancel-variant="outline-secondary"
        :ok-title="$t('general.deleteConfirm')"
        hide-footer
        :cancel-title="$t('general.deleteCancel')"
        centered
        :title="modalTitle"
        @hidden="resetModal"
    >
      <h4 class="mt-1">{{ modalBody }}</h4>

      <b-form @submit.prevent="saveData" v-if="actionStatus !== 'approve'">
        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form-group :label="$t('work.approveComment') + '*'" class="my-2">
            <validation-provider #default="{ errors }" :name="$t('work.approveComment')" rules="required">
              <b-form-input v-model="approveComment" :state="errors.length > 0 ? false : null"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>

      <div class="mt-3 text-right">
        <b-button variant="outline-secondary" class="mb-1 mr-1" @click="handleCancel" :disabled="isBtnDisabled">
          {{ $t('general.deleteCancel') }}
        </b-button>
        <b-button variant="primary" class="mb-1" @click="handleOk" :disabled="isBtnDisabled">
          <b-spinner small v-if="isBtnDisabled"/>
          {{ $t('general.deleteConfirm') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BModal, BForm, BSpinner} from 'bootstrap-vue'
import {required} from '@validations'
import formMixins from "@/mixins/formMixins"
import WorkViewDetail from "@/components/work/WorkViewDetail"
import WorkViewDateOfWork from "@/components/work/WorkViewDateOfWork"
import WorkViewContractor from "@/components/work/WorkViewContractor"
import WorkViewJsa from "@/components/work/WorkViewJsa"
import WorkViewActivity from "@/components/work/WorkViewActivity"
import {WorkService} from "@/services"

const workService = new WorkService()

export default {
  name: 'WorkFormApprove',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BModal,
    BSpinner,
    WorkViewDetail,
    WorkViewDateOfWork,
    WorkViewContractor,
    WorkViewJsa,
    WorkViewActivity,
  },
  mixins: [formMixins],
  async created() {
    this.id = this.$route.params.id
    await this.getData()
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    approveStatus: '',
    approveComment: '',
    result: {},
    modalTitle: '',
    modalBody: '',
    actionStatus: ''
  }),
  computed: {
    setBtnBlock() {
      return this.$store.getters['app/currentBreakPoint'] === 'sm' || this.$store.getters['app/currentBreakPoint'] === 'xs'
    },
    setBtnSize() {
      if (this.$store.getters['app/currentBreakPoint'] === 'sm' || this.$store.getters['app/currentBreakPoint'] === 'xs') {
        return 'lg'
      } else {
        return 'md'
      }
    },
    setIconSize() {
      if (this.$store.getters['app/currentBreakPoint'] === 'sm' || this.$store.getters['app/currentBreakPoint'] === 'xs') {
        return '22'
      } else {
        return '14'
      }
    }
  },
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.result = result
        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageWork'})
      }
    },
    handleCancel() {
      this.$refs['modal-approve'].hide()
    },
    handleOk() {
      if (this.actionStatus === 'approve') {
        this.saveData()
      } else {
        this.$refs.validateForm.validate().then(success => {
          if (success) {
            this.saveData()
          }
        })
      }
    },
    saveData() {
      let actionData = {
        approveStatus: this.approveStatus,
        approveComment: this.approveComment,
      }
      this.isBtnDisabled = true
      workService.approval(this.id, actionData)
          .then(({isSuccess, data}) => {
            if (isSuccess) {
              this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
              this.$refs['modal-approve'].hide()
              this.$router.replace({name: 'pageWork'})
            } else {
              this.isBtnDisabled = false
              this.$store.commit('main/setToastError', data)
            }
          })
    },
    resetModal() {
      this.actionStatus = ''
      this.modalTitle = ''
      this.modalBody = ''
    },
    approveData() {
      this.actionStatus = 'approve'
      this.approveStatus = 'Y'
      this.modalTitle = this.$t('general.btnApprove')
      this.modalBody = this.$t('general.alertApprove')
      this.$refs['modal-approve'].show()
    },
    disapproveData() {
      this.actionStatus = 'disapprove'
      this.approveStatus = 'N'
      this.modalTitle = this.$t('general.btnDisapprove')
      this.modalBody = this.$t('general.alertDisapprove')
      this.$refs['modal-approve'].show()
    },
    sendBackData() {
      this.actionStatus = 'sendBack'
      this.approveStatus = 'B'
      this.modalTitle = this.$t('general.btnBack')
      this.modalBody = this.$t('general.alertSendBack')
      this.$refs['modal-approve'].show()
    }
  }
}
</script>
